import { combineReducers } from 'redux';

import alertsReducer from './alerts-reducer';
import conditionsReducer from './conditions-reducer';
import drupalReducer from './drupal-reducer';
import ecommerceReducer from './ecommerce-reducer';
import liftTrailReportReducer from './lift-trail-report-reducer';
import liftsReducer from './lifts-reducer';
import liveWeatherReducer from './live-weather-reducer';
import loggingReducer from './logging-reducer';
import mediaReducer from './media-reducer';
import nodeReducer from './node-reducer';
import parkingReducer from './parking-reducer';
import placesReducer from './places-reducer';
import roadsReducer from './roads-reducer';
import sensorsReducer from './sensors-reducer';
import skipperReducer from './skipper-reducer';
import snowReportsReducer from './snow-reports-reducer';
import statusReducer from './status-reducer';
import terrainsReducer from './terrains-reducer';
import trailsReducer from './trails-reducer';
import weatherReducer from './weather-reducer';

export default combineReducers({
  conditions: conditionsReducer,
  drupal: drupalReducer,
  ecommerce: ecommerceReducer,
  logging: loggingReducer,
  media: mediaReducer,
  node: nodeReducer,
  sensors: sensorsReducer,
  status: statusReducer,
  weather: weatherReducer,
  liftTrailReport: liftTrailReportReducer,
  alerts: alertsReducer,
  parking: parkingReducer,
  trails: trailsReducer,
  lifts: liftsReducer,
  roads: roadsReducer,
  liveWeather: liveWeatherReducer,
  places: placesReducer,
  terrains: terrainsReducer,
  snowReports: snowReportsReducer,
  skipper: skipperReducer,
});
