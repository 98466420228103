import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, {
  useContext,
} from 'react';
import styled from 'styled-components';

import { TvMain } from '@powdr/components/tv';
import { ThemeProps as Prop, StaticProfiles } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { componentFactory, themeUtil } from '@powdr/utils';

import GlobalStyle from '../../utils/global-styles';

const SiteWrapper = styled.div`
  height: 100vh;
  background-color: ${() => themeUtil(Prop.BACKGROUND, null, StaticProfiles.TV)};
`;

function TvStaticLayout({
  data,
}) {
  const { defaultTheme } = useContext(AppContext);
  const {
    name,
    displayTitle,
    relationships,
    displayTime,
  } = data.tvData;
  const components = relationships?.tvComponents;

  return (
    <>
      <GlobalStyle theme={defaultTheme} />
      <SiteWrapper>
        <TvMain
          title={displayTitle || name}
          navPages={[]}
          activeItemData={components[0]}
          alertData={{
            enabled: data?.tvData?.alertOverlayEnabled,
            message: data?.tvData?.alertMainMessage,
            subMessage: data?.tvData?.alertSubMessage,
            iconName: data?.tvData?.alertIconName,
            iconColor: data?.tvData?.alertIconColor,
          }}
        >
          {componentFactory(
            components || [],
            undefined,
            undefined,
            {
              displayTime,
            },
          )}
        </TvMain>
      </SiteWrapper>
    </>
  );
}

TvStaticLayout.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default TvStaticLayout;

export const query = graphql`
  query TvStaticLayout($fullPath: String){
    site {
      ...SiteMetadataQuery
    }
    tvData: taxonomyTermTvNavigation( field_navigation_link: { eq: $fullPath } ) {
      ...TvPageQuery
    }
  }
`;
