import PropTypes from 'prop-types';
import React from 'react';

import {
  Header, Sidebar, NavigationDrawer, DorDrawer,
} from './components';

export const OverlayWrapper = ({
  levels,
  showStickyNav,
  isPageModal,
  isLandingPage,
}) => (
  <>
    <Header
      levels={levels}
      showStickyNav={showStickyNav}
      isPageModal={isPageModal}
      isLandingPage={isLandingPage}
    />
    {(!isLandingPage) && <Sidebar />}
    {(!isLandingPage) && <NavigationDrawer />}
    {(!isLandingPage) && <DorDrawer />}
  </>
);

OverlayWrapper.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showStickyNav: PropTypes.bool.isRequired,
  isPageModal: PropTypes.bool.isRequired,
  isLandingPage: PropTypes.bool,
};

OverlayWrapper.defaultProps = {
  isLandingPage: false,
};
