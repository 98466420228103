import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRateCalendarByRoom } from '@powdr/stores';

export const useSkipperRoomPrice = (roomId) => {
  if (!roomId) return null;

  const dispatch = useDispatch();
  const useRoomData = useSelector((state) => state.skipper);
  const { data } = useRoomData;
  const [roomPrice, setRoomPrice] = useState();

  useEffect(() => {
    dispatch(getRateCalendarByRoom(roomId));
  }, [dispatch, roomId]);

  const getLowestRoomPrice = (roomData) => roomData?.months
    ?.reduce((allDates, month) => {
      month.dates.forEach((d) => allDates.push(d));
      return allDates;
    }, [])
    ?.filter((d) => d.displayPrice && d.status === 'available')
    ?.reduce((prev, curr) => (prev.displayPrice < curr.displayPrice ? prev : curr))
    ?.displayPrice || null;

  useEffect(() => {
    if (data && data?.[roomId]) {
      setRoomPrice(getLowestRoomPrice(data[roomId]));
    }
  }, [data, roomId]);

  return roomPrice;
};
