import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { formatDate } from '@powdr/utils';

import {
  SKIPPER_RATE_CALENDAR_PENDING,
  SKIPPER_RATE_CALENDAR_SUCCESS,
  SKIPPER_RATE_CALENDAR_ERROR,
} from '../types';

export const getRateCalendarByRoom = (roomId) => async (dispatch, getState) => {
  const { rateCalendarStatus } = getState().skipper;

  dispatch({
    type: SKIPPER_RATE_CALENDAR_PENDING,
    payload: [],
  });

  if (!rateCalendarStatus?.[roomId]) {
    const startDate = new Date(Date.now());
    const endDate = new Date();
    endDate.setDate(startDate.getDate() + 365);
    const startDateString = `${formatDate(startDate).year}-${formatDate(startDate).month.num}-${formatDate(startDate).day.num}`;
    const endDateString = `${formatDate(endDate).year}-${formatDate(endDate).month.num}-${formatDate(endDate).day.num}`;
    const url = `https://cash-api.skipperhospitality.com/rateCalendar?hotelId=ZION_LODGE_UTAH&roomId=${roomId}&requestId=${uuidv4()}&startDate=${startDateString}&endDate=${endDateString}`;

    try {
      const res = await axios.get(url);
      dispatch({
        type: SKIPPER_RATE_CALENDAR_SUCCESS,
        payload: {
          [roomId]: res.data.data,
        },
      });
    } catch (error) {
      dispatch({
        type: SKIPPER_RATE_CALENDAR_ERROR,
        payload: error,
      });
    }
  }
};
