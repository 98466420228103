import { FetchStatus } from '@powdr/constants';

import { SKIPPER_RATE_CALENDAR_PENDING, SKIPPER_RATE_CALENDAR_SUCCESS, SKIPPER_RATE_CALENDAR_ERROR } from '../types';

const initialState = {
  data: null,
  status: FetchStatus.IDLE,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SKIPPER_RATE_CALENDAR_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case SKIPPER_RATE_CALENDAR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        status: FetchStatus.SUCCESS,
      };
    case SKIPPER_RATE_CALENDAR_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
