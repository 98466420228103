import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints,
  FontSize,
  GlobalLayoutValues,
  FontFamily,
} from '@powdr/constants';
import { componentColor, getFontStyles, rgbaTransparencyToHex } from '@powdr/utils';

const CPNT = Components.HEADER;

// TODO: cleanup all header styles for different header types
// TODO: Replace custom colors with component color pattern where hardcoded property names are
export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  position: fixed;
  width: 100%;
  z-index: 75;
  top: 0;
  transition:
      top ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease,
      background-color ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease;

  ${({ opacity }) => (`
    background-color: ${componentColor(Prop.NAV_BG, CPNT, Prop.BACKGROUND)}${rgbaTransparencyToHex(opacity / 100)};
  `)};

  ${Breakpoints.mobile(css`
    top: ${({ minimizeWrapper }) => ((minimizeWrapper) ? `-${GlobalLayoutValues.MOBILE.HEADER_HEIGHT.ACTIVE}` : 0)}px;
  `)}
`;

export const HeaderLayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  transition:
      height ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease,
      padding-top ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease,
      padding-bottom ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease;

  ${Breakpoints.desktopOnly(css`
    height: ${({ minimizeWrapper }) => ((minimizeWrapper)
    ? GlobalLayoutValues.DESKTOP.HEADER_HEIGHT.INACTIVE
    : GlobalLayoutValues.DESKTOP.HEADER_HEIGHT.ACTIVE)}px;
    padding-top: ${({ minimizeWrapper }) => ((minimizeWrapper) ? '10px' : '25px')};
    padding-bottom: ${({ minimizeWrapper }) => ((minimizeWrapper) ? '10px' : '25px')};
    padding-left: 50px;
    padding-right: 50px;
  `)}

  ${Breakpoints.mobile(css`
    position: relative;
    padding: 15px 0;
    height: ${GlobalLayoutValues.MOBILE.HEADER_HEIGHT.ACTIVE}px;
  `)}
`;

export const NavigationDrawerToggle = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  pointer-events: all;
  height: 100%;
  color: ${({ isWrapperMinimized, isScrolled }) => componentColor((isWrapperMinimized || isScrolled) ? Prop.NAV_TOGGLE_TXT_SCROLLED : Prop.NAV_TOGGLE_TXT, Components.NAVIGATION_TOGGLE, Prop.BTN_BG)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG30, undefined, undefined, CPNT, 'button')};

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BTN_BG)};
    outline-offset: 0.2em;
  }

  svg {
    fill: ${({ isWrapperMinimized, isScrolled }) => componentColor((isWrapperMinimized || isScrolled) ? Prop.NAV_TOGGLE_ICON_SCROLLED : Prop.NAV_TOGGLE_ICON, Components.NAVIGATION_TOGGLE, Prop.BTN_BG)};
    color: ${({ isWrapperMinimized, isScrolled }) => componentColor((isWrapperMinimized || isScrolled) ? Prop.NAV_TOGGLE_ICON_SCROLLED : Prop.NAV_TOGGLE_ICON, Components.NAVIGATION_TOGGLE, Prop.BTN_BG)};
  }

  ${Breakpoints.mobile(css`
    //margin-right: 10px;
    padding: 0 10px 0 10px;
  `)}
`;

export const NavigationDrawerToggleIcon = styled(Icon)`
  width: 40px;
  height: 100%;

  ${Breakpoints.mobile(css`
    width: 30px;
  `)}
`;

export const DrawerText = styled.div``;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${Breakpoints.desktopOnly(css`
    padding: 8px 0;
    flex: 1;
  `)}
`;

export const HeaderCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${({ $isLeftJustified }) => (($isLeftJustified) ? 'flex-start' : 'center')};
  align-items: center;
  width: 100%;
  height: 100%;

  ${Breakpoints.mobile(css`
    ${({ $isLeftJustified }) => ($isLeftJustified) && 'padding-left: 20px;'};
    flex: unset;
  `)}
`;

export const HeaderRight = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: flex-end;
`;
